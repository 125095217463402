import React from "react";
import ChatBox from "./components/ChatBox";
import "./index.css";

function App() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <ChatBox />
    </div>
  );
}

export default App;
