import React, { useState, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import ReactMarkdown from 'react-markdown';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Note: In production, you should make API calls from a backend
});

const MarkdownToolbar = ({ onAction }) => {
  const tools = [
    { icon: '↺', action: 'undo', label: 'Undo' },
    { icon: '↻', action: 'redo', label: 'Redo' },
    { icon: 'H1', action: 'h1', label: 'Heading 1' },
    { icon: 'H2', action: 'h2', label: 'Heading 2' },
    { icon: 'H3', action: 'h3', label: 'Heading 3' },
    { icon: 'B', action: 'bold', label: 'Bold' },
    { icon: 'I', action: 'italic', label: 'Italic' },
    { icon: '•', action: 'ul', label: 'Bullet List' },
    { icon: '1.', action: 'ol', label: 'Numbered List' },
    { icon: '"', action: 'quote', label: 'Quote' },
    { icon: '⌘', action: 'code', label: 'Code' },
    { icon: '</>', action: 'codeblock', label: 'Code Block' },
  ];

  return (
    <div className="flex items-center gap-1 p-2 border-b border-gray-200 mb-3">
      {tools.map((tool) => (
        <button
          key={tool.action}
          onClick={() => onAction(tool.action)}
          className="p-1.5 hover:bg-gray-100 rounded text-sm font-medium text-gray-700"
          title={tool.label}
        >
          {tool.icon}
        </button>
      ))}
    </div>
  );
};

const ChatBox = () => {
  const [prompt, setPrompt] = useState('');
  const [responses, setResponses] = useState({ left: '', right: '' });
  const [loading, setLoading] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [editedResponse, setEditedResponse] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const textareaRef = useRef(null);
  const editTextareaRef = useRef(null);
  const editSectionRef = useRef(null);

  const resetSession = () => {
    setPrompt('');
    setResponses({ left: '', right: '' });
    setSelectedResponse(null);
    setEditedResponse('');
    setLoading(false);
    setSubmitting(false);
    // Focus back on the prompt textarea
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const adjustTextareaHeight = (ref) => {
    const textarea = ref.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    adjustTextareaHeight(textareaRef);
  }, [prompt]);

  useEffect(() => {
    if (selectedResponse) {
      adjustTextareaHeight(editTextareaRef);
      // Scroll to edit section when a response is selected
      if (editSectionRef.current) {
        editSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [editedResponse, selectedResponse]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    setLoading(true);
    setSelectedResponse(null);
    setEditedResponse('');
    try {
      const [response1, response2] = await Promise.all([
        openai.chat.completions.create({
          model: "gpt-4",
          messages: [{ role: "user", content: prompt }]
        }),
        openai.chat.completions.create({
          model: "gpt-4",
          messages: [{ role: "user", content: prompt }]
        })
      ]);

      setResponses({
        left: response1.choices[0].message.content,
        right: response2.choices[0].message.content
      });
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to get responses. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleResponseSelect = (side) => {
    setSelectedResponse(side);
    setEditedResponse(responses[side]);
  };

  const handleToolbarAction = (action) => {
    const textarea = editTextareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;
    let newText = text;
    let newCursorPos = start;

    const wrapText = (prefix, suffix = '') => {
      const selectedText = text.substring(start, end);
      newText = text.substring(0, start) + prefix + selectedText + suffix + text.substring(end);
      newCursorPos = start + prefix.length + selectedText.length + suffix.length;
    };

    switch (action) {
      case 'h1':
        wrapText('# ');
        break;
      case 'h2':
        wrapText('## ');
        break;
      case 'h3':
        wrapText('### ');
        break;
      case 'bold':
        wrapText('**', '**');
        break;
      case 'italic':
        wrapText('*', '*');
        break;
      case 'ul':
        wrapText('- ');
        break;
      case 'ol':
        wrapText('1. ');
        break;
      case 'quote':
        wrapText('> ');
        break;
      case 'code':
        wrapText('`', '`');
        break;
      case 'codeblock':
        wrapText('```\n', '\n```');
        break;
      default:
        return;
    }

    setEditedResponse(newText);
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(newCursorPos, newCursorPos);
    }, 0);
  };

  const handleFinishEditing = async () => {
    if (!selectedResponse || !editedResponse.trim()) {
      alert('Please select and edit a response before finishing.');
      return;
    }

    setSubmitting(true);
    
    const sessionData = {
      timestamp: new Date().toISOString(),
      originalPrompt: prompt,
      responses: {
        option1: responses.left,
        option2: responses.right
      },
      decision: {
        selectedOption: selectedResponse === 'left' ? 1 : 2,
        originalContent: responses[selectedResponse],
        editedContent: editedResponse
      }
    };

    try {
      const response = await fetch('/api/submit-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sessionData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit session data');
      }

      console.log('Session data submitted:', sessionData);
      alert('Session completed successfully!');
    } catch (error) {
      console.error('Error submitting session:', error);
      alert('Failed to submit session. Starting new session.');
    } finally {
      setSubmitting(false);
      resetSession(); // Reset session regardless of success or failure
    }
  };

  return (
    <div className="flex justify-center p-5">
      <div className="w-full max-w-[90%] flex flex-col gap-6">
        {/* Input Section */}
        <div className="w-full max-w-2xl mx-auto bg-white rounded-xl shadow-md p-6">
          <div className="flex items-start gap-4 mb-6">
            <div className="text-2xl leading-none text-indigo-600 bg-indigo-50 p-2 rounded-lg">
              💬
            </div>
            <div className="text-left">
              <h2 className="text-lg text-gray-800 mb-1">Start a Chat</h2>
              <p className="text-sm text-gray-600">Write a prompt to start a chat session with the model.</p>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="w-full">
            <textarea 
              ref={textareaRef}
              value={prompt}
              onChange={handlePromptChange}
              onKeyDown={handleKeyDown}
              placeholder="Start typing here..."
              className="w-full p-3 border border-gray-200 rounded-lg text-sm mb-2 focus:outline-none focus:border-indigo-500 resize-none overflow-hidden min-h-[40px]"
              style={{ height: 'auto' }}
            />
            <div className="flex justify-between items-center text-sm text-gray-600">
              <span>
                Press <kbd className="px-1.5 py-0.5 text-xs bg-gray-100 border border-gray-300 rounded">Shift</kbd>
                {' + '}
                <kbd className="px-1.5 py-0.5 text-xs bg-gray-100 border border-gray-300 rounded">Enter</kbd>
                {' to submit your message.'}
              </span>
              <button 
                type="submit" 
                disabled={loading}
                className="bg-indigo-500 text-white px-4 py-2 rounded-lg text-sm transition-colors hover:bg-indigo-600 disabled:bg-indigo-300 disabled:cursor-not-allowed"
              >
                {loading ? 'Getting Responses...' : 'Submit Message'}
              </button>
            </div>
          </form>
        </div>

        {/* Response Section */}
        {(responses.left || responses.right) && (
          <div className="w-full bg-white rounded-xl shadow-md p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="text-2xl leading-none text-indigo-600 bg-indigo-50 p-2 rounded-lg">
                👥
              </div>
              <div className="text-left">
                <h2 className="text-lg text-gray-800 mb-1">Select the best response</h2>
                <p className="text-sm text-gray-600">When the responses are fully loaded, select the best response from the options.</p>
              </div>
            </div>
            
            <div className="grid grid-cols-2 gap-8">
              <div 
                className={`bg-gray-50 rounded-lg p-6 border-2 transition-colors cursor-pointer
                  ${selectedResponse === 'left' ? 'border-indigo-500' : 'border-transparent hover:border-indigo-200'}`}
                onClick={() => handleResponseSelect('left')}
              >
                <h3 className="text-base text-gray-800 mb-3">Response 1</h3>
                <div className="text-sm text-gray-600 leading-relaxed">
                  <ReactMarkdown 
                    components={{
                      p: ({children}) => <p className="mb-4">{children}</p>,
                      h1: ({children}) => <h1 className="text-xl font-bold mb-4">{children}</h1>,
                      h2: ({children}) => <h2 className="text-lg font-bold mb-3">{children}</h2>,
                      h3: ({children}) => <h3 className="text-base font-bold mb-2">{children}</h3>,
                      ul: ({children}) => <ul className="list-disc pl-4 mb-4">{children}</ul>,
                      ol: ({children}) => <ol className="list-decimal pl-4 mb-4">{children}</ol>,
                      li: ({children}) => <li className="mb-1">{children}</li>,
                      code: ({children}) => <code className="bg-gray-100 px-1 rounded">{children}</code>,
                      pre: ({children}) => <pre className="bg-gray-800 text-white p-3 rounded mb-4 overflow-x-auto">{children}</pre>,
                    }}
                  >
                    {responses.left}
                  </ReactMarkdown>
                </div>
              </div>
              <div 
                className={`bg-gray-50 rounded-lg p-6 border-2 transition-colors cursor-pointer
                  ${selectedResponse === 'right' ? 'border-indigo-500' : 'border-transparent hover:border-indigo-200'}`}
                onClick={() => handleResponseSelect('right')}
              >
                <h3 className="text-base text-gray-800 mb-3">Response 2</h3>
                <div className="text-sm text-gray-600 leading-relaxed">
                  <ReactMarkdown 
                    components={{
                      p: ({children}) => <p className="mb-4">{children}</p>,
                      h1: ({children}) => <h1 className="text-xl font-bold mb-4">{children}</h1>,
                      h2: ({children}) => <h2 className="text-lg font-bold mb-3">{children}</h2>,
                      h3: ({children}) => <h3 className="text-base font-bold mb-2">{children}</h3>,
                      ul: ({children}) => <ul className="list-disc pl-4 mb-4">{children}</ul>,
                      ol: ({children}) => <ol className="list-decimal pl-4 mb-4">{children}</ol>,
                      li: ({children}) => <li className="mb-1">{children}</li>,
                      code: ({children}) => <code className="bg-gray-100 px-1 rounded">{children}</code>,
                      pre: ({children}) => <pre className="bg-gray-800 text-white p-3 rounded mb-4 overflow-x-auto">{children}</pre>,
                    }}
                  >
                    {responses.right}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit Section */}
        {selectedResponse && (
          <>
            <div ref={editSectionRef} className="w-full bg-white rounded-xl shadow-md p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="text-2xl leading-none text-indigo-600 bg-indigo-50 p-2 rounded-lg">
                  ✏️
                </div>
                <div className="text-left flex-grow">
                  <h2 className="text-lg text-gray-800 mb-1">Refine your response</h2>
                  <p className="text-sm text-gray-600">Edit the response to make it more appropriate for the prompt.</p>
                </div>
                <button
                  onClick={() => setResponses(prev => ({ ...prev, [selectedResponse]: editedResponse }))}
                  className="bg-indigo-500 text-white px-4 py-2 rounded-lg text-sm transition-colors hover:bg-indigo-600"
                >
                  Save Changes
                </button>
              </div>
              
              <div className="bg-gray-50 rounded-lg p-6">
                <MarkdownToolbar onAction={handleToolbarAction} />
                <div className="grid grid-cols-2 gap-8">
                  <div>
                    <h3 className="text-base text-gray-800 mb-3">Edit</h3>
                    <textarea
                      ref={editTextareaRef}
                      value={editedResponse}
                      onChange={(e) => setEditedResponse(e.target.value)}
                      className="w-full p-3 border border-gray-200 rounded-lg text-sm mb-2 focus:outline-none focus:border-indigo-500 resize-none min-h-[300px] font-mono"
                    />
                  </div>
                  <div>
                    <h3 className="text-base text-gray-800 mb-3">Preview</h3>
                    <div className="text-sm text-gray-600 leading-relaxed">
                      <ReactMarkdown 
                        components={{
                          p: ({children}) => <p className="mb-4">{children}</p>,
                          h1: ({children}) => <h1 className="text-xl font-bold mb-4">{children}</h1>,
                          h2: ({children}) => <h2 className="text-lg font-bold mb-3">{children}</h2>,
                          h3: ({children}) => <h3 className="text-base font-bold mb-2">{children}</h3>,
                          ul: ({children}) => <ul className="list-disc pl-4 mb-4">{children}</ul>,
                          ol: ({children}) => <ol className="list-decimal pl-4 mb-4">{children}</ol>,
                          li: ({children}) => <li className="mb-1">{children}</li>,
                          code: ({children}) => <code className="bg-gray-100 px-1 rounded">{children}</code>,
                          pre: ({children}) => <pre className="bg-gray-800 text-white p-3 rounded mb-4 overflow-x-auto">{children}</pre>,
                        }}
                      >
                        {editedResponse}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Finish Editing Button */}
            <div className="w-full flex justify-center">
              <button
                onClick={handleFinishEditing}
                disabled={submitting}
                className="bg-green-500 text-white px-8 py-3 rounded-lg text-sm font-medium transition-colors hover:bg-green-600 disabled:bg-green-300 disabled:cursor-not-allowed"
              >
                {submitting ? 'Submitting...' : 'Finish Editing'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatBox; 